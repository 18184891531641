import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FormikValues, useFormikContext } from 'formik';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useCustomerData } from 'context/customer-context';
import LabeledGrid from 'components/LabeledGrid';
import { MoneyField, StringField } from 'containers/RLA/components/Field';
import { useDataContext } from 'context/data-context';
import { dropdownOptions, firstOptionOptions } from './types';
import useStyles from './styles';
import StaticFileUpload from '../StaticFileUpload';

interface Props {
  year: number;
  blockId: number;
  formStructure: any;
  isEditing?: boolean;
  extraInformation: any;
  isReview?: boolean;
  show: boolean;
}

const DocumentYearForm = memo(
  ({ year, blockId, formStructure, isEditing, extraInformation, isReview, show = true }: Props) => {
    const { loanApplication } = useDataContext();
    const { hgb, loanId, foundation } = extraInformation;
    const isLastYear = useMemo(() => Number(year) === Number(foundation), [foundation, year]);
    const { values, handleSubmit, setFieldValue } = useFormikContext<FormikValues>();
    const { balancingForms } = useCustomerData();
    const [fileNames, setFileNames] = useState<any>({});
    const [fileIds, setFileIds] = useState<any>({});
    const [fileSources, setFileSources] = useState<any>({});
    const classes = useStyles();

    useEffect(() => {
      const fieldKeys = [
        `b${blockId}_field_f2`,
        `b${blockId}_field_f8`,
        `b${blockId}_field_f9`,
        `b${blockId}_field_f10`,
        `b${blockId}_field_f11`,
      ];
      const names: any = {};
      const ids: any = {};
      const sources: any = {};
      fieldKeys.forEach((key) => {
        const field = formStructure[key];
        if (field?.fieldMetada) {
          const fieldName = field.name;
          names[fieldName] = field.fieldMetada.value_display;
          ids[fieldName] = field.fieldMetada.file_id;
          sources[fieldName] = field.fieldMetada.file_source;
        }
      });
      setFileNames(names);
      setFileIds(ids);
      setFileSources(sources);
    }, [blockId, formStructure]);

    const setFileName = useCallback((name: string, newName: string) => {
      setFileNames((prev: any) => ({ ...prev, [name]: newName }));
    }, []);

    const setFileId = useCallback((name: string, newFileId: string) => {
      setFileIds((prev: any) => ({ ...prev, [name]: newFileId }));
    }, []);

    const setFileSource = useCallback((name: string, newFileSource: string) => {
      setFileSources((prev: any) => ({ ...prev, [name]: newFileSource }));
    }, []);

    const getLinkDocument = useCallback(
      (slug: string) => balancingForms[slug]?.template || '',
      [balancingForms],
    );

    const renderSelectField = useCallback(
      (props: any) => {
        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
          setFieldValue(props.field.name, event.target.value);
          handleSubmit();
        };

        return (
          <StringField
            customClassesInput={{ root: classes.selectLabel }}
            showLabelEmpty={props.showLabelEmpty || true}
            {...props.field}
            {...props}
            onChange={handleChange}
            readOnly={!isEditing}
          />
        );
      },
      [classes.selectLabel, handleSubmit, isEditing, setFieldValue],
    );

    const renderTextField = useCallback(
      (props: any) => (
        <MoneyField
          customClassesInput={{ root: classes.selectLabel }}
          showLabelEmpty={props.showLabelEmpty || true}
          {...props.field}
          {...props}
          readOnly={!isEditing}
          moreInputProps={{ inputProps: { allowNegative: true } }}
        />
      ),
      [classes.selectLabel, isEditing],
    );

    const compareNumbersFields = useMemo(() => {
      if (blockId === 4)
        return {
          a: formStructure[`b${blockId}_field_f12`]?.name,
          b: formStructure[`b${blockId}_field_f13`]?.name,
          c: formStructure[`b${blockId}_field_f14`]?.name,
          d: formStructure[`b${blockId}_field_f15`]?.name,
        };
      return {
        a: formStructure[`b${blockId + 1}_field_f4`]?.name,
        b: formStructure[`b${blockId + 1}_field_f5`]?.name,
        c: formStructure[`b${blockId + 1}_field_f6`]?.name,
        d: formStructure[`b${blockId + 1}_field_f7`]?.name,
      };
    }, [blockId, formStructure]);

    const blockSlug = useMemo(
      () => formStructure[`b${blockId}_field_f1`]?.blockSlug,
      [blockId, formStructure],
    );

    const renderFirstOption = useCallback(
      () => (
        <>
          <Grid item xs={12} className={classes.alertHGB}>
            {loanApplication?.hgb_version === '2023-01' ? (
              <Typography className={classes.questionTitle}>
                {hgb ? (
                  <>
                    Vom Steuerberater gestempelte <b>und</b> unterschriebene <b>Bilanz {year}</b>{' '}
                    (inkl.{' '}
                    <b>
                      {isLastYear ? '' : 'Vorjahresvergleich und'} Gewinnermittlung +
                      Kontennachweise
                    </b>
                    ): *
                  </>
                ) : (
                  <>
                    Vom Steuerberater gestempelte <b>und</b> unterschriebene{' '}
                    <b>Gewinnermittlung {year}</b> (inkl.{' '}
                    <b>{isLastYear ? '' : 'Vorjahresvergleich und'} Kontennachweise</b>
                    ): *
                  </>
                )}
              </Typography>
            ) : (
              <>
                {hgb ? (
                  <Typography className={classes.questionTitle}>
                    Vom Steuerberater gestempelte <b>und</b> unterschriebene <b>Bilanz {year}</b>{' '}
                    (inkl. <b>Vorjahresvergleich und Gewinnermittlung + Kontennachweise</b>): *
                  </Typography>
                ) : (
                  <Typography className={classes.questionTitle}>
                    Vom Steuerberater gestempelte <b>und</b> unterschriebene{' '}
                    <b>Gewinnermittlung {year}</b> (inkl.{' '}
                    <b>Vorjahresvergleich und Kontennachweise</b>): *
                  </Typography>
                )}
                <Typography className={classes.questionSubTitle}>
                  Sollte aufgrund des Alters der Unternehmung noch kein Vorjahresvergleich vorhanden
                  sein, reicht auch ein Jahresabschluss ohne Vorjahresvergleich.
                </Typography>
              </>
            )}
            <StaticFileUpload
              data={formStructure[`b${blockId}_field_f2`]?.fieldMetada}
              name={formStructure[`b${blockId}_field_f2`]?.name}
              value={values[formStructure[`b${blockId}_field_f2`]?.name]}
              valueDisplay={fileNames ? fileNames[formStructure[`b${blockId}_field_f2`]?.name] : ''}
              blockSlug={blockSlug}
              year={year}
              loanId={loanId}
              disabled={!isEditing}
              setFileName={setFileName}
              setFileId={setFileId}
              fileId={fileIds[formStructure[`b${blockId}_field_f2`]?.name]}
              setFileSource={setFileSource}
              fileSource={fileSources[formStructure[`b${blockId}_field_f2`]?.name]}
            />
            <Box display="flex" flexDirection="row" mt={1} style={{ gap: 6 }}>
              <Info color="primary" style={{ width: 22, marginTop: '-2px' }} />
              <Box>
                <Typography className={classes.helperText}>
                  Bei den Kontennachweisen bzw. nachrichtlichem Teil bitte zwingend darauf achten,
                  dass auch alle sonstigen Konten (Bestandskonten wie z.B. Bankkonten) ausgewiesen
                  werden. Siehe bitte Seite 8 von der Muster-Gewinnermittlung.
                </Typography>
                <Typography className={classes.helperText}>
                  Eine Muster-Gewinnermittlung finden Sie{' '}
                  <a
                    href={getLinkDocument(
                      hgb
                        ? 'HGB_Blianz_Gewinnermittlung_Kontennachweis'
                        : 'EUER_Gewinnermittlung_Kontennachweis',
                    )}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    hier.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
          {!hgb && (
            <>
              <LabeledGrid
                customClassLabel={classes.questionTitle}
                label={
                  <>
                    Werden in den Kontennachweisen der oben hochgeladenen Gewinnermittlung alle
                    sonstigen Konten (Bestandskonten wie z.B. Bankkonten) ausgewiesen)? (Siehe bitte
                    Seite 8 von der{' '}
                    <a
                      href={getLinkDocument('EUER_Gewinnermittlung_Kontennachweis')}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.linkUnderline}
                    >
                      Muster-Gewinnermittlung
                    </a>
                    )
                  </>
                }
                smLabel={12}
                smChild={12}
                required
              >
                <Field
                  component={renderSelectField}
                  name={formStructure[`b${blockId}_field_f3`]?.name}
                  variant="outlined"
                  fullWidth
                  options={firstOptionOptions}
                  size="medium"
                  customLabel="Bitte auswählen."
                  showLabelEmpty={false}
                  onChange={handleSubmit}
                  showCompleteCheck={
                    values[formStructure[`b${blockId}_field_f3`]?.name] &&
                    values[formStructure[`b${blockId}_field_f3`]?.name] !== '' &&
                    values[formStructure[`b${blockId}_field_f3`]?.name] !== undefined
                  }
                />
              </LabeledGrid>
              {values[formStructure[`b${blockId}_field_f3`]?.name] === 'False' && (
                <Box
                  p={2}
                  pt={1}
                  borderRadius="5px"
                  width="100%"
                  m="15px"
                  mt={0}
                  style={{ backgroundColor: '#f7f7f7' }}
                >
                  <Grid item xs={12} className={classes.alertHGB}>
                    <Typography variant="body1" className={classes.titleSub}>
                      Bitte geben Sie im Folgenden die Werte für das Geschäftsjahr und das Vorjahr
                      der oben hochgeladenen Gewinnermittlung an: *
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell align="center">{year}</TableCell>
                            {isLastYear ? null : <TableCell align="center">{year - 1}</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">Summe Anlagevermögen</TableCell>
                            <TableCell component="th" scope="row">
                              <Field
                                component={renderTextField}
                                name={formStructure[`b${blockId}_field_f4`]?.name}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Bitte hier schreiben"
                                isCurrency
                                onBlur={handleSubmit}
                                showCompleteCheck={
                                  values[formStructure[`b${blockId}_field_f4`]?.name] &&
                                  values[formStructure[`b${blockId}_field_f4`]?.name] !== '' &&
                                  values[formStructure[`b${blockId}_field_f4`]?.name] !== undefined
                                }
                              />
                            </TableCell>
                            {isLastYear ? null : (
                              <TableCell align="left">
                                <Field
                                  component={renderTextField}
                                  name={compareNumbersFields.a}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  placeholder="Bitte hier schreiben"
                                  isCurrency
                                  onBlur={handleSubmit}
                                  showCompleteCheck={
                                    values[compareNumbersFields.a] &&
                                    values[compareNumbersFields.a] !== '' &&
                                    values[compareNumbersFields.a] !== undefined
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Summe betriebliche Forderungen</TableCell>
                            <TableCell component="th" scope="row">
                              <Field
                                component={renderTextField}
                                name={formStructure[`b${blockId}_field_f5`]?.name}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Bitte hier schreiben"
                                isCurrency
                                onBlur={handleSubmit}
                                showCompleteCheck={
                                  values[formStructure[`b${blockId}_field_f5`]?.name] &&
                                  values[formStructure[`b${blockId}_field_f5`]?.name] !== '' &&
                                  values[formStructure[`b${blockId}_field_f5`]?.name] !== undefined
                                }
                              />
                            </TableCell>
                            {isLastYear ? null : (
                              <TableCell align="left">
                                <Field
                                  component={renderTextField}
                                  name={compareNumbersFields.b}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  placeholder="Bitte hier schreiben"
                                  isCurrency
                                  onBlur={handleSubmit}
                                  showCompleteCheck={
                                    values[compareNumbersFields.b] &&
                                    values[compareNumbersFields.b] !== '' &&
                                    values[compareNumbersFields.b] !== undefined
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Summe aller betrieblichen Bankkonten</TableCell>
                            <TableCell component="th" scope="row">
                              <Field
                                component={renderTextField}
                                name={formStructure[`b${blockId}_field_f6`]?.name}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Bitte hier schreiben"
                                isCurrency
                                onBlur={handleSubmit}
                                showCompleteCheck={
                                  values[formStructure[`b${blockId}_field_f6`]?.name] &&
                                  values[formStructure[`b${blockId}_field_f6`]?.name] !== '' &&
                                  values[formStructure[`b${blockId}_field_f6`]?.name] !== undefined
                                }
                              />
                            </TableCell>
                            {isLastYear ? null : (
                              <TableCell align="left">
                                <Field
                                  component={renderTextField}
                                  name={compareNumbersFields.c}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  placeholder="Bitte hier schreiben"
                                  isCurrency
                                  onBlur={handleSubmit}
                                  showCompleteCheck={
                                    values[compareNumbersFields.c] &&
                                    values[compareNumbersFields.c] !== '' &&
                                    values[compareNumbersFields.c] !== undefined
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Summe betriebliche Verbindlichkeiten</TableCell>
                            <TableCell component="th" scope="row">
                              <Field
                                component={renderTextField}
                                name={formStructure[`b${blockId}_field_f7`]?.name}
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Bitte hier schreiben"
                                isCurrency
                                onBlur={handleSubmit}
                                showCompleteCheck={
                                  values[formStructure[`b${blockId}_field_f7`]?.name] &&
                                  values[formStructure[`b${blockId}_field_f7`]?.name] !== '' &&
                                  values[formStructure[`b${blockId}_field_f7`]?.name] !== undefined
                                }
                              />
                            </TableCell>
                            {isLastYear ? null : (
                              <TableCell align="left">
                                <Field
                                  component={renderTextField}
                                  name={compareNumbersFields.d}
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  placeholder="Bitte hier schreiben"
                                  isCurrency
                                  onBlur={handleSubmit}
                                  showCompleteCheck={
                                    values[compareNumbersFields.d] &&
                                    values[compareNumbersFields.d] !== '' &&
                                    values[compareNumbersFields.d] !== undefined
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </>
      ),
      [
        classes,
        loanApplication?.hgb_version,
        hgb,
        year,
        isLastYear,
        formStructure,
        blockId,
        values,
        fileNames,
        blockSlug,
        loanId,
        isEditing,
        setFileName,
        setFileId,
        fileIds,
        setFileSource,
        fileSources,
        getLinkDocument,
        renderSelectField,
        handleSubmit,
        renderTextField,
        compareNumbersFields,
      ],
    );

    const renderSecondOption = useCallback(
      (yearSecond: number) => (
        <>
          <Grid item xs={12} className={classes.alertHGB}>
            <Typography className={classes.questionTitle}>Jahres-BWA {yearSecond} *</Typography>
            <StaticFileUpload
              data={formStructure[`b${blockId}_field_f8`]?.fieldMetada}
              disabled={!isEditing}
              name={formStructure[`b${blockId}_field_f8`]?.name}
              value={values[formStructure[`b${blockId}_field_f8`]?.name]}
              valueDisplay={fileNames ? fileNames[formStructure[`b${blockId}_field_f8`]?.name] : ''}
              year={year}
              blockSlug={blockSlug}
              loanId={loanId}
              setFileName={setFileName}
              setFileId={setFileId}
              fileId={fileIds[formStructure[`b${blockId}_field_f8`]?.name]}
              setFileSource={setFileSource}
              fileSource={fileSources[formStructure[`b${blockId}_field_f8`]?.name]}
            />
            <Box display="flex" flexDirection="row" mt={1} style={{ gap: 6 }}>
              <Info color="primary" style={{ width: 22, marginTop: '-2px' }} />
              <Box>
                <Typography className={classes.helperText}>
                  Eine Muster-BWA finden Sie{' '}
                  <a
                    href={getLinkDocument(hgb ? 'HGB_Year_BWA' : 'EUER_Year_BWA')}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    hier.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.alertHGB}>
            <Typography className={classes.questionTitle}>
              Jahres-Summen- und Saldenliste {yearSecond} *
            </Typography>
            <StaticFileUpload
              data={formStructure[`b${blockId}_field_f9`]?.fieldMetada}
              disabled={!isEditing}
              name={formStructure[`b${blockId}_field_f9`]?.name}
              value={values[formStructure[`b${blockId}_field_f9`]?.name]}
              valueDisplay={fileNames ? fileNames[formStructure[`b${blockId}_field_f9`]?.name] : ''}
              year={year}
              blockSlug={blockSlug}
              loanId={loanId}
              setFileName={setFileName}
              setFileId={setFileId}
              fileId={fileIds[formStructure[`b${blockId}_field_f9`]?.name]}
              setFileSource={setFileSource}
              fileSource={fileSources[formStructure[`b${blockId}_field_f9`]?.name]}
            />
            <Box display="flex" flexDirection="row" mt={1} style={{ gap: 6 }}>
              <Info color="primary" style={{ width: 22, marginTop: '-2px' }} />
              <Box>
                <Typography className={classes.helperText}>
                  Eine Muster-Summen- und Saldenliste finden Sie{' '}
                  <a
                    href={getLinkDocument(
                      hgb ? 'HGB_Year_Summen_Saldenliste' : 'EUER_Year_Summen_Saldenliste',
                    )}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    hier.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </>
      ),
      [
        classes,
        formStructure,
        blockId,
        isEditing,
        values,
        fileNames,
        year,
        blockSlug,
        loanId,
        setFileName,
        setFileId,
        fileIds,
        setFileSource,
        fileSources,
        getLinkDocument,
        hgb,
      ],
    );

    const renderThirdOption = useCallback(
      (yearThird: number) => (
        <>
          <Grid item xs={12} className={classes.alertHGB}>
            <Typography className={classes.questionTitle}>
              Aktuelle BWA {yearThird} (nicht älter als 5 Monate) *
            </Typography>
            <StaticFileUpload
              data={formStructure[`b${blockId}_field_f10`]?.fieldMetada}
              disabled={!isEditing}
              name={formStructure[`b${blockId}_field_f10`]?.name}
              value={values[formStructure[`b${blockId}_field_f10`]?.name]}
              valueDisplay={
                fileNames ? fileNames[formStructure[`b${blockId}_field_f10`]?.name] : ''
              }
              year={year}
              blockSlug={blockSlug}
              loanId={loanId}
              setFileName={setFileName}
              setFileId={setFileId}
              fileId={fileIds[formStructure[`b${blockId}_field_f10`]?.name]}
              setFileSource={setFileSource}
              fileSource={fileSources[formStructure[`b${blockId}_field_f10`]?.name]}
            />
            <Box display="flex" flexDirection="row" mt={1} style={{ gap: 6 }}>
              <Info color="primary" style={{ width: 22, marginTop: '-2px' }} />
              <Box>
                <Typography className={classes.helperText}>
                  Eine Muster-BWA finden Sie{' '}
                  <a
                    href={getLinkDocument(hgb ? 'HGB_BWA' : 'EUER_BWA')}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    hier.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.alertHGB}>
            <Typography className={classes.questionTitle}>
              Aktuelle vollständig eröffnete Summen- und Saldenliste {yearThird} (nicht älter als 5
              Monate) *
            </Typography>
            <StaticFileUpload
              data={formStructure[`b${blockId}_field_f11`]?.fieldMetada}
              disabled={!isEditing}
              name={formStructure[`b${blockId}_field_f11`]?.name}
              value={values[formStructure[`b${blockId}_field_f11`]?.name]}
              valueDisplay={
                fileNames ? fileNames[formStructure[`b${blockId}_field_f11`]?.name] : ''
              }
              year={year}
              blockSlug={blockSlug}
              loanId={loanId}
              setFileName={setFileName}
              setFileId={setFileId}
              fileId={fileIds[formStructure[`b${blockId}_field_f11`]?.name]}
              setFileSource={setFileSource}
              fileSource={fileSources[formStructure[`b${blockId}_field_f11`]?.name]}
            />
            <Box display="flex" flexDirection="row" mt={1} style={{ gap: 6 }}>
              <Info color="primary" style={{ width: 22, marginTop: '-2px' }} />
              <Box>
                <Typography className={classes.helperText}>
                  Eine Muster-Summen und Saldenliste finden Sie{' '}
                  <a
                    href={getLinkDocument(
                      hgb ? 'HGB_Summen_Saldenliste' : 'EUER_Summen_Saldenliste',
                    )}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    hier.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </>
      ),
      [
        classes,
        formStructure,
        blockId,
        isEditing,
        values,
        fileNames,
        year,
        blockSlug,
        loanId,
        setFileName,
        setFileId,
        fileIds,
        setFileSource,
        fileSources,
        getLinkDocument,
        hgb,
      ],
    );

    const [optionsDropdown, setOptionsDropdown] = useState<any>([]);

    useEffect(() => {
      const options = dropdownOptions({
        year,
        hgb,
        isReview,
        hgbVersion: loanApplication?.hgb_version!,
      });
      if (
        options.length === 1 &&
        !isReview &&
        values?.[formStructure[`b${blockId}_field_f1`]?.name] !== options[0][0]
      ) {
        setFieldValue(formStructure[`b${blockId}_field_f1`]?.name, options[0][0]);
      }
    }, [
      blockId,
      formStructure,
      hgb,
      isReview,
      loanApplication?.hgb_version,
      setFieldValue,
      values,
      year,
    ]);

    useEffect(() => {
      const options = dropdownOptions({
        year,
        hgb,
        isReview,
        hgbVersion: loanApplication?.hgb_version!,
      });
      setOptionsDropdown(options);
    }, [hgb, isReview, loanApplication?.hgb_version, year]);

    return show ? (
      <>
        <Grid item xs={12} className={classes.alertHGB}>
          <Typography variant="body1" className={classes.titleYear}>
            Finanzkennzahlen {year}
          </Typography>
        </Grid>
        {optionsDropdown.length === 1 && !isReview ? null : (
          <LabeledGrid
            customClassLabel={classes.questionTitle}
            label={`In welchem Format liegen die Finanzkennzahlen für ${year} vor?`}
            smLabel={12}
            smChild={12}
            required
          >
            <Field
              component={renderSelectField}
              name={formStructure[`b${blockId}_field_f1`]?.name}
              variant="outlined"
              fullWidth
              options={optionsDropdown}
              size="medium"
              customLabel="Bitte auswählen."
              showLabelEmpty={false}
              onChange={handleSubmit}
              showCompleteCheck={
                values[formStructure[`b${blockId}_field_f1`]?.name] &&
                values[formStructure[`b${blockId}_field_f1`]?.name] !== '' &&
                values[formStructure[`b${blockId}_field_f1`]?.name] !== undefined
              }
            />
          </LabeledGrid>
        )}
        {values[formStructure[`b${blockId}_field_f1`].name] === 'ja' && renderFirstOption()}
        {values[formStructure[`b${blockId}_field_f1`].name] === 'bwa' && renderSecondOption(year)}
        {values[formStructure[`b${blockId}_field_f1`].name] === 'susa' && renderThirdOption(year)}
      </>
    ) : null;
  },
);

DocumentYearForm.displayName = 'DocumentYearForm';

export default DocumentYearForm;
