import { HGBVersion } from 'utils/commonTypes/types';

export const dropdownOptions = ({
  year,
  hgb,
  hgbVersion,
  isReview,
}: {
  year: number;
  hgb: boolean;
  hgbVersion: HGBVersion;
  isReview?: boolean;
}) => {
  const defaultOptions = [
    ['ja', `Jahresabschluss ${hgb ? '(Bilanz)' : '(Gewinnermittlung)'} ${year}`],
    ['bwa', `Jahres-BWA und Jahres-Summen- und Saldenliste ${year}`],
    ['susa', 'Aktuelle BWA und Summen- und Saldenliste (nicht älter als 5 Monate)'],
    ['nofi', 'Noch keine Finanzkennzahlen vorhanden'],
  ];

  const hgbLogic: Record<HGBVersion, Record<number, boolean[]>> = {
    '2023-01': {
      2023: [false, false, true],
      2022: [true, true, false],
      2021: [true, false, false],
      2020: [true, false, false],
    },
    '2023-02': {
      2023: [false, false, true],
      2022: [true, true, false],
      2021: [true, false, false],
      2020: [true, false, false],
    },
    '2024-01': {
      2024: [false, false, true, true],
      2023: [true, true, true, false],
      2022: [true, true, false, false],
      2021: [true, false, false, false],
    },
    '2024-02': {
      2024: [false, false, true, false],
      2023: [true, true, false, false],
      2022: [true, true, false, false],
      2021: [true, false, false, false],
    },
    '2024-03': {
      2024: [false, false, true, false],
      2023: [true, true, false, false],
      2022: [true, false, false, false],
      2021: [true, false, false, false],
    },
    '2025-01': {
      2025: [false, false, true, true],
      2024: [true, true, true, false],
      2023: [true, true, false, false],
      2022: [true, false, false, false],
    },
    '2025-02': {
      2025: [false, false, true, false],
      2024: [true, true, false, false],
      2023: [true, true, false, false],
      2022: [true, false, false, false],
    },
  };

  const options = hgbLogic[hgbVersion];

  if (options) {
    if (!options[year]) return defaultOptions;

    if (isReview) {
      return options[year].map((value: boolean, index: number) =>
        !value ? [...defaultOptions[index], true] : defaultOptions[index],
      );
    }
    return options[year].reduce(
      (acc: any[], value: boolean, index: number) =>
        value ? [...acc, defaultOptions[index]] : acc,
      [],
    );
  }

  return defaultOptions;
};

export const firstOptionOptions = [
  ['True', `Ja, die sonstigen Konten werden ausgewiesen`],
  ['False', `Nein, die sonstigen Konten werden nicht ausgewiesen`],
];
