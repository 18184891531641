import { FormikValues } from 'formik';

import {
  Company,
  CompanyAddressValues,
  CompanyDataFormValues,
  Person,
  RefArrays,
  Share,
} from 'context/customer-context/types';
import { Salutation } from 'constants/salutations';
import { Title } from 'constants/titles';

export enum RefinancingChannels {
  Solarisbank = 'compartment_2019_001',
  Anadibank = 'anadi_bank',
  Solarisbank2025001 = 'compartment_2025_001',
}

export enum LoanOfferStatus {
  CREATED = 'CREATED',
  SELECTED = 'SELECTED',
  READY_TO_SEND = 'READY_TO_SEND',
  SEND = 'SEND',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum QualificationFile {
  DOC_ADDITIONAL_QUALIFICATION = 'Additional Qualification',
}

export enum LoanOfferTypeFile {
  // For Anadi Bank
  DOC_AB_FINANCIAL_STATEMENT_1_XBRL = 'AB_FINANCIAL_STATEMENT_1_XBRL',
  DOC_AB_FINANCIAL_STATEMENT_2_XBRL = 'AB_FINANCIAL_STATEMENT_2_XBRL',
  DOC_AB_CREDITREFORM_CREDIT_REPORT_PDF = 'AB_CREDITREFORM_CREDIT_REPORT_PDF',
  DOC_AB_CREDITREFORM_CREDIT_REPORT_JSON = 'AB_CREDITREFORM_CREDIT_REPORT_JSON',
  DOC_AB_FINAL_TILGUNSPLAN = 'AB_FINAL_TILGUNSPLAN',
  // Rating section
  DOC_RC_SCORECARD = 'RC_SCORECARD',
  DOC_RC_AMORTIZATION_SCHEDULE = 'RC_AMORTIZATION_SCHEDULE',
  // Vertragsgenerierung section
  DOC_RC_UNSIGNED_LOAN_AGREEMENT = 'RC_UNSIGNED_LOAN_AGREEMENT',
  DOC_RC_UNSIGNED_LOAN_AGREEMENT_WITH_WATERMARK = 'RC_UNSIGNED_LOAN_AGREEMENT_WITH_WATERMARK',
}

export enum LoanOfferLabelFile {
  // For Anadi Bank
  AB_FINANCIAL_STATEMENT_1_XBRL = 'Jahresabschluss aktuell XBRL 6.0',
  AB_FINANCIAL_STATEMENT_2_XBRL = 'Jahresabschluss Vorjahr XBRL 6.0',
  AB_CREDITREFORM_CREDIT_REPORT_PDF = 'Creditreform Wirtschaftsauskunft PDF',
  AB_CREDITREFORM_CREDIT_REPORT_JSON = 'Creditreform Wirtschaftsauskunft JSON',
  AB_FINAL_TILGUNSPLAN = 'Austrian Anadi Bank - finaler Tilgungsplan',
  // Rating section
  RC_SCORECARD = 'Scorecard',
  RC_AMORTIZATION_SCHEDULE = 'Tilgunsplan',
  // Vertragsgenerierung section
  RC_UNSIGNED_LOAN_AGREEMENT = 'Nicht unterschriebener Darlehensvertrag',
  RC_UNSIGNED_LOAN_AGREEMENT_WITH_WATERMARK = 'Nicht unterschriebener Darlehensvertrag mit Wasserzeichen',
}

export interface LoanOfferFile {
  id: number;
  file_type: LoanOfferTypeFile;
  send_to_customer: boolean | null;
  send_to_broker: boolean | null;
  send_to_fronting_bank: boolean | null;
  datafile: string;
  filename: string;
  filetitle: string;
  loan_application_offer: number;
}

export interface LoanOffer {
  id: number;
  partner_contact_name: string;
  partner_contact_number: string;
  refinancing_channel_pd: string | null;
  refinancing_channel_score_class: string;
  refinancing_channel_interest_rate: string | null;
  loan_amount: string;
  loan_paid_out: string;
  agio: string | null;
  agio_amount: string;
  repayment_method: string | null;
  duration: string | null;
  rate_amount: string;
  nominal_interest_rate: string;
  effective_interest_rate: string | null;
  offer_sent_on: Date[];
  offer_accepted_by: string | null;
  offer_declined_by: string | null;
  status: LoanOfferStatus;
  refinancing_channel: RefinancingChannels;
  files: LoanOfferFile[];
}

export interface LoanOfferFormatted extends LoanOffer {
  staticData?: { [key: string]: any };
}

export interface DropdownOptions {
  liquidity: [string, string][];
  final_repayment_method: [string, string][];
}

export type HGBVersion =
  | '2023-01'
  | '2023-02'
  | '2024-01'
  | '2024-02'
  | '2024-03'
  | '2025-01'
  | '2025-02';

export interface LoanApplication {
  id: number;
  tipster_partner: any;
  steps_data: StepsData[];
  form_data: FormData;
  profession: number;
  profession_name: string;
  loan_product: number;
  loan_type: number;
  loan_usage: number;
  loan_taker: string;
  loan_usage_display: string;
  cooperation_partner: string | null;
  status_display: string;
  external_status: string;
  has_solarisbank_error: boolean | null;
  number_of_comments: number;
  loan_applicant_persons: LoanApplicantPerson[];
  loan_applicant_companies: LoanApplicantCompany[];
  beneficial_owner_persons: BeneficialOwnerPerson[];
  customer_profile_id: number;
  created: string;
  loan_id: string | null;
  status: string;
  loan_amount: string;
  final_loan_amount: string | null;
  final_loan_paid_out: string | null;
  final_rate_amount: string | null;
  duration: number;
  final_duration: number;
  interest_rate: string;
  agio: string;
  final_agio: string | null;
  final_agio_amount: string | null;
  final_repayment_method: string | null;
  final_effective_interest_rate: string | null;
  repayment_method: string | null;
  effective_interest_rate: string | null;
  loan_total: string;
  monthly_rate: string;
  submitted_date: string | null;
  fronting_bank: RefinancingChannels | null;
  solarisbank_id: any;
  solarisbank_status: any;
  tipster_employee: any;
  loan_paid_out: string;
  agio_amount: string;
  rate_amount: string;
  nominal_interest_rate: string;
  final_nominal_interest_rate?: string | null;
  loan_product_name?: string;
  loan_type_name?: string;
  loan_taker_id?: number;
  cooperation_partner_id: number | null;
  cooperation_partner_pool: number | null;
  paid_out_reviewer?: string;
  paid_out_requirements?: string[];
  loan_active_date?: string | null;
  dropdown_options: DropdownOptions;
  final_equity_ratio: string | null;
  final_liquidity_ratio: string | null;
  final_profitability_ratio: string | null;
  final_debt_ratio: string | null;
  final_expenses_ratio: string | null;
  final_financial_plan_score: number;
  partner_contact_name?: string | null;
  partner_contact_number?: string | null;
  loan_taker_role?: string | null;
  refinancing_channel_interest_rate?: string | null;
  confirmation_submitted_view?: string | null;
  confirmation_submitted_at?: string | null;
  hgb_version?: HGBVersion;
  process_version: string;
}

export interface StepsData {
  slug: string;
  title: string;
  order?: number;
}

export interface FormData {
  [key: string]: string | number | boolean | null;
}

export interface History {
  timestamp: string;
  verb: string;
}

export interface LoanApplicantPerson {
  id: number;
  person_id: string;
  salutation: Salutation;
  title: Title;
  first_name: string;
  last_name: string;
  family_status: string;
  birth_name: string;
  birthday: any;
  birth_country: CountryCodePrefix;
  birth_city: string;
  citizenship: CountryCodePrefix;
  occupational_group: string;
  fatca: any;
  children: any[];
  dependents: any[];
  tax_revenue_office: string;
  tax_id: string;
  tax_nr: string;
  private_address_street: string;
  private_address_postcode: string;
  private_address_house_n: string;
  private_address_city: string;
  private_address_country: CountryCodePrefix;
  private_address_extra: string;
  contact_email: string;
  contact_phone: string;
  contact_international_prefix: CountryCodePrefix;
  contact_mobile: string;
  contact_prefix_mobile_phone: CountryCodePrefix;
  shares: Share[];
  additional_information: string;
  is_contact_person: boolean;
  customer_profile_relation?: Object;
  email_contract_signing_sent_date?: string | null;
  contract_signed?: string | null;
  contract_signed_on?: string | null;
  external_id?: string | null;
}

export interface CountryCodePrefix {
  code: string;
  prefix: string;
  country?: string;
  nationality?: string;
}

export interface LoanApplicantCompany {
  id: number;
  business_id: string;
  business: string;
  business_address_street: string;
  business_address_house_n: string;
  business_address_extra: string;
  business_address_postcode: string;
  business_address_city: string;
  international_prefix: CountryCodePrefix;
  phone_number: string;
  email_business: string;
  legal_form: string;
  cooperation_form: string;
  commercial_register: string;
  commercial_register_number: string;
  number_employees: number;
  foundation_date: any;
  tax_id: string;
  additional_information: string;
  hgb_accordance: boolean;
  shares: any[];
  status: string;
  business_address_country: CountryCodePrefix;
  external_id: any;
  tax_country?: string | null;
  fatca?: boolean | null;
  customer_profile_relation?: Object;
  solarisbank_id?: string;
  short_label?: string;
}

export interface BeneficialOwnerPerson {
  id: number;
  person_id: string;
  salutation: string;
  title: string;
  first_name: string;
  last_name: string;
  family_status: string;
  birth_name: string;
  birthday: any;
  birth_country: CountryCodePrefix;
  birth_city: string;
  citizenship: CountryCodePrefix;
  occupational_group: string;
  fatca: any;
  children: any[];
  dependents: any[];
  tax_revenue_office: string;
  tax_id: string;
  tax_nr: string;
  private_address_street: string;
  private_address_postcode: string;
  private_address_house_n: string;
  private_address_city: string;
  private_address_country: CountryCodePrefix;
  private_address_extra: string;
  contact_email: string;
  contact_phone: string;
  contact_international_prefix: CountryCodePrefix;
  contact_mobile: string;
  contact_prefix_mobile_phone: CountryCodePrefix;
  shares: Share[];
  additional_information: string;
  is_contact_person: boolean;
  customer_profile_relation?: Object;
  email_contract_signing_sent_date?: string | null;
  contract_signed?: string | null;
  contract_signed_on?: string | null;
  external_id?: string | null;
}

export type IType = 'IC' | 'EC';

export interface IComment {
  comment: string;
  id: number;
  title: string;
  type: IType;
}

export interface LoanApplicationOverview {
  status: string;
  id: number;
}

export interface Step {
  based_component: null | string;
  can_be_duplicated: boolean;
  instances: Instance[];
  section_path: string;
  title: string;
}

export interface Instance {
  all_blocks_approved: boolean;
  comments: number;
  internal_coments: any[];
  section_path: string;
  index: number;
  blocks: Blocks[];
  required_fields_filled: boolean;
}

export interface BlocksInstance {
  status: string;
  section_path: string;
  status_display: string;
  questions: Question[];
  index: number;
}
export interface Blocks {
  depending_field: string;
  visibility_value: string;
  instances: BlocksInstance[];
  based_component?: string;
  title: string;
  section_path: string;
  can_be_duplicated: boolean;
}

export type CreateOrOvertake = 'overtake' | 'create' | 'previousStep' | '';

export type LegalForm =
  | 'AG'
  | 'eK'
  | 'Fb'
  | 'GbR'
  | 'GmbH'
  | 'GmbH_&_Co_KG'
  | 'GmbH_&_Co_KGaA'
  | 'KG'
  | 'KGaA'
  | 'OHG'
  | 'PartG'
  | 'PartGmbB'
  | 'UG'
  | 'UG_&_Co_KG';

export type CustomerProfileRole = 'OWP' | 'LRP' | 'LAP' | 'BOP' | 'BOC' | 'OWC' | 'LAC';

export type GeneralRole = 'OW' | 'BO' | 'LR' | 'LA';

type CooperationForm = {
  value: string;
  name: string;
};

export type LegalFormsTypes = {
  [key1 in LegalForm]: LegalFormsType;
};

export type LegalFormsType = {
  label: string;
  short_label: string;
  ranges: {
    [key2 in GeneralRole]: {
      min: number | null;
      max: number | null;
      roles: CustomerProfileRole[];
    };
  };
  docs: {
    [key: string]: {
      name: string;
      required: boolean;
      max_docs: number;
    };
  };
  cooperation_form: CooperationForm[];
};

export type RadioButtonsValues = 'yes' | 'no' | '' | null;

export type YesOrNo = 'yes' | 'no' | '';

export type CompanyOrPerson = 'company' | 'person';

type FileOrName = File | string | null;

export type DocumentFileType = {
  fileOrName: FileOrName;
  urlFile?: string;
  id?: number;
};

export type DocumentFiles = {
  [key: string]: DocumentFileType[];
};

export interface CountryType {
  code: string;
  country: string;
  prefix: string;
  nationality: string;
  countryNationality?: string;
}

export type BOChild = {
  id: number;
  type: CompanyOrPerson;
  name: string;
  parentID: number;
  roles: CustomerProfileRole[];
  shares: Share[];
  deleteOWC: YesOrNo;
  deleteBOC: YesOrNo;
  deleteOWP: YesOrNo;
  deleteLRP: YesOrNo;
  deleteLAP: YesOrNo;
  deleteBOP: YesOrNo;
};

export interface BODeleteModalInitialValues {
  deleteData?: Company | null;
  isOwnerCompany?: YesOrNo;
  children: BOChild[];
}

export type RemoveRef = {
  removedRoles: CustomerProfileRole[];
  id: number;
};

type RemoveRefType = 'OW' | 'BO' | 'LRP' | 'LAP';

export type RemoveRefContext = {
  [key1 in RemoveRefType]: number[];
};

export type SetNewFunction = (refs: RefArrays) => void;

export interface CustomColors {
  blockBackgroundColor?: string;
  completeBlockColor?: string;
  summaryBackgroundColor?: string;
}

export interface FastFieldProps {
  disabled: boolean;
  name: string;
  formik: FormikValues;
}

export type Level = 1 | 2 | 3;

export type PersonOrCompany = 'person' | 'company' | '';

export interface Block {
  title: string;
  section_path?: string;
  slug?: string;
}

export interface SaveData {
  [x: string]: string;
}

export interface SolarisbankAccountBalanceType {
  modified: string;
  created: string;
  balance: string;
  available: string;
  bookings_link: string;
}

export interface AnadibankAccountBalanceType {
  bank: string;
  overall_contingent: number;
  contingent_used: number;
  contingent_link: string;
}

export interface BalanceType {
  value: number;
  unit: string;
  currency: string;
}

export interface AnadibankEmail {
  received_date: Date;
  email_type: 'loan_rating_check_successful' | 'loan_webid_check_successful';
}

export interface IdentificationStatusType {
  persons: PersonsType;
  business?: BusinessType;
  webid_combined_zip_file?: AnadibankCombinedZipFile;
  anadibank_emails: AnadibankEmail[];
}

export interface BusinessType {
  data: BusinessDataType;
  missing_docs: MissingDocument[];
  compliance_questions: ComplianceQuestion[];
}

export interface PersonsType {
  status: string;
  data: SolarisbankPersonsDataType[] | AnadibankPersonsDataType[];
}

export interface BusinessDataType {
  status: string;
  method: string;
  identification_id: string;
  missing_info: string;
}

export interface SolarisbankPersonsDataType {
  id: number;
  person_id: number;
  first_name: string;
  last_name: string;
  idnow_id: string;
  idnow_url: string;
  postident_id: string;
  email_sent: string;
  current_status: string;
  sent_idnow: boolean;
  sent_postident: boolean;
  records: RecordType[];
  contact_email?: string | null;
  email_content_idnow?: string | null;
}

export interface AnadibankPersonsDataType {
  id: number;
  transaction_id: number;
  action_id: number;
  ident_url: string;
  ident_urL_valid_until: string;
  ident_status: 'successful' | 'failed' | '';
  email_ident_sent_on?: Date;
  email_content_ident: string;
  ident_finished_on?: Date;
  ident_identified_on?: Date;
  ident_rejection_reason?: string;
  qes_status: 'successful' | 'failed' | '';
  qes_finished_on?: Date;
  qes_certificate: any;
  file_response_status: 'successful' | 'failed' | '';
  file_response_received_on?: Date;
  file_response: string;
  person_id: number;
  first_name: string;
  last_name: string;
  loan_application: number;
  contact_email?: string | null;
}

export interface AnadibankCombinedZipFile {
  id: number;
  created: string;
  created_by_id: string;
  datafile: string;
  filename: string;
  filetitle: string;
  loan_application: number;
}

export interface RecordType {
  method: string;
  status: string;
  created: string;
}

export interface ReceivedType {
  id: number;
  email_sent: Date;
  sent_idnow?: boolean;
  sent_webid?: boolean;
  sent_postident: boolean;
}

export interface ComplianceQuestion {
  id: number;
  answer_text: string | null;
  sent_solarisbank_on: Date | null;
  canceled_on: Date | null;
  business_identification: number;
  question_id: string;
  question_text: string;
}

export interface MissingDocument {
  id: number;
  business_identification: number;
  doc_file: string | null;
  doc_identification: string | null;
  doc_type: string;
  canceled_on: Date | null;
  sent_solarisbank_on: Date | null;
}

export type ValidateBOResponse = boolean | (boolean | boolean[][])[][];

export interface TipsterPool {
  pool_id: string;
  tipster_pool: number;
  link: string;
  first_name: string;
  last_name: string;
  tipster_pool_company: string;
  link_settings: number;
  loan_application_link_settings: number;
  login_link_settings: number;
  status: boolean;
  legal_form: string;
  created_at: string;
}

export interface TipsterPartner {
  tipster_partner_id: string;
  tipster_partner: number;
  link: string;
  tipster_partner_slug: string;
  link_settings: number;
  tipster_partner_token: string;
  loan_application_link_settings: number;
  login_link_settings: number;
  status: boolean;
  tipster_pool_company: string;
  tipster_partner_company: string;
  legal_form: string;
  first_name: string;
  last_name: string;
  created_at: string;
}

export interface TipsterEmployee {
  tipster_employee_id: string;
  tipster_employee: number;
  link: string;
  first_name?: string;
  last_name?: string;
  tipster_partner: number;
  link_settings: number;
  loan_application_link_settings: number;
  login_link_settings: number;
  status: boolean;
  tipster_pool_company: string;
  tipster_partner_company: string;
  company: string;
  legal_form: string;
  created_at: string;
}

export interface B2cRecord {
  id: number;
  tipster_partner: {
    id: number;
    name: string;
    slug: string;
    email: string;
    token: string;
    identifier: string;
    company: string;
    legal_form: string;
    last_name: string;
    first_name: string;
    phone_number: string;
    custom_policy_type: string;
    tipster_partner_id: string;
    international_prefix: string;
    tipster_link_settings: number;
  };
  tipster_employee: {
    id: number;
    name: string;
    slug: string;
    email: string;
    token: string;
    identifier: string;
    company: string;
    legal_form: string;
    last_name: string;
    first_name: string;
    phone_number: string;
    tipster_employee_id: string;
    international_prefix: string | null;
    tipster_link_settings: number;
  };
  b2c_pool: {
    id: number;
    email: string;
    name: string;
    pool_id: string;
    identifier: string;
    company: string;
    legal_form: string;
    salutation: Salutation | '';
    title: Title | '';
    last_name: string;
    first_name: string;
    phone_number: string;
    international_prefix: string;
    default_policy_type: string;
    slug: string;
  };
  tipster_partner_status: 0 | 1 | -1 | null;
  tipster_employee_status: 0 | 1 | -1 | null;
  tipster_pool_status: 0 | 1 | -1 | null;
}

export type FieldMaskType = 'LONGER' | 'EUR-CURRENCY' | 'PERCENTAGE' | 'NUMBERS-ONLY' | 'HTML';

export interface ChoiceType {
  value: string;
  name: string;
}

export interface ChoiceGroupType {
  name: string;
  choices: ChoiceType[];
}

export interface DynamicObject {
  [key: string]: any;
}

export interface QuestionField {
  choice_group: ChoiceGroupType | ChoiceGroupType[];
  multiple_choices: string | number | null;
  choice_value_display: string | null;
  category: string | null;
  field_mask: FieldMaskType;
  value_display: string | null;
  field_type: string | null;
  label: string;
  order: number | null;
  slug: string;
  value: string | number | boolean | null;
  depending_field: string;
  visibility_value: string;
  help_text: string;
  label_help_text: string;
  example_text: string | null;
  placeholder: string;
  description: string;
  max_length: number | null;
  default: string;
  linked_model_name: string | null;
  linked_field_name: string;
  linked_field_role: string;
  linked_document_type: string | null;
  linked_db_id: number | null;
  formula: string;
  field_validator: string | null;
  is_protected: boolean;
  is_readonly: boolean;
  choice_rules: { [key: string]: string };
  custom_choice_group?: { name: string };
  is_required: boolean;
  file_type: string;
  file_id: string;
  file_source: string;
  size: number;
  accept: string;
  max: number;
  min: number;
  is_admin_only: boolean;
}

export interface QuestionInstance {
  comments: number;
  section_path: string;
  index: number;
  fields: QuestionField[];
}

export interface Question {
  can_be_duplicated: boolean;
  mandatory_type: string;
  section_path: string;
  title: string;
  visibility_value: string;
  depending_field: string;
  help_text: string;
  order: number;
  slug: string;
  max_duplicated_number: number | null;
  layout: 'V' | 'H';
  num_column: number;
  duplicated_path: string;
  instances: QuestionInstance[];
}

export interface ExtendedPerson extends Person {
  haveMoreRoles: YesOrNo;
  allocation: string;
  sideDeal: YesOrNo;
  type: 'person';
}

export interface ExtendedCompany extends Company {
  haveMoreRoles: YesOrNo;
  allocation: string;
  sideDeal: YesOrNo;
  type: 'company';
}

type StepType = 'company' | 'people' | 'companies' | 'lap';

export interface InPlanningInitialStateTypeChangeLF {
  people: ExtendedPerson[];
  companies: ExtendedCompany[];
  step: StepType;
  currentPerson: number;
  addPeople: ExtendedPerson[];
  addCompanies: ExtendedCompany[];
  deletePeople: number[];
  deleteCompanies: number[];
  data: { legalForm: LegalForm; companyName: string };
  selectedPerson?: number;
}

export interface InPlanningInitialStateType {
  data: CompanyDataFormValues;
  address: CompanyAddressValues;
  people: ExtendedPerson[];
  step: StepType;
  currentPerson: number;
  addPeople: ExtendedPerson[];
  deletePeople: number[];
  selectedPerson?: number;
}

export const scoreClassesOptions = [
  ['A', 'A'],
  ['B', 'B'],
  ['C', 'C'],
  ['D', 'D'],
  ['E', 'E'],
  ['F', 'F'],
  ['G', 'G'],
  ['H', 'H'],
  ['I', 'I'],
  ['K', 'K'],
  ['L', 'L'],
  ['M', 'M'],
  ['N', 'N'],
  ['O', 'O'],
  ['P', 'P'],
];
