import { FormikValues, useFormikContext } from 'formik';

import UploadField from 'components/UploadField';
import { UploadFieldWrapper } from 'components/CaptiqField/wrappers';
import { useCustomerData } from 'context/customer-context';

interface Props {
  data: any;
  disabled: any;
  value: any;
  valueDisplay: any;
  blockSlug: any;
  loanId: any;
  name: any;
  year: number;
  setFileName: (name: string, newName: string) => void;
  setFileId: (name: string, newFileId: string) => void;
  fileId?: string;
  setFileSource: (name: string, newFileSource: string) => void;
  fileSource?: string;
}

const StaticFileUpload = ({
  data = {},
  disabled,
  value,
  valueDisplay,
  blockSlug,
  loanId,
  name,
  year,
  setFileName,
  setFileId,
  setFileSource,
  fileId,
  fileSource,
}: Props) => {
  const { setFieldValue, handleSubmit } = useFormikContext<FormikValues>();
  const { legalForm, hgbAccordance } = useCustomerData();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFileAdd = (v: any, d = null, _fn = null, id = null, tempFileSource: string) => {
    setFileId(name, id!);
    setFileSource(name, tempFileSource);
    setFieldValue(name, v);
    setFileName(name, d || '');
    setTimeout(() => {
      handleSubmit();
    }, 0);
  };

  return (
    <UploadFieldWrapper>
      <UploadField
        key={data.slug}
        disabled={disabled || data.is_readonly}
        value={value}
        filename={data.is_readonly ? data.label : valueDisplay}
        onFilesAdded={handleFileAdd}
        size={data.size}
        accept={data.accept}
        slug={data.slug}
        sectionPath={`${blockSlug}__${data.slug}`}
        loanId={`${loanId}`}
        category={data.category}
        year={year}
        fileType={data.file_type}
        linkedDbId={data.linked_db_id}
        fileSource={fileSource || data.file_source}
        fileId={fileId || data.file_id}
        legalForm={legalForm}
        hgbAccordance={hgbAccordance}
      />
    </UploadFieldWrapper>
  );
};

export default StaticFileUpload;
