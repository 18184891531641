import { HGBVersion } from 'utils/commonTypes/types';

export const CURRENT_YEAR: { [key in HGBVersion]: number } = {
  '2023-01': 2023,
  '2023-02': 2023,
  '2024-01': 2024,
  '2024-02': 2024,
  '2024-03': 2024,
  '2025-01': 2025,
  '2025-02': 2025,
};
